<template>
  <div>
    <el-card>
      <all-log :dialogLog="dialogLog" />

      <div class="menu-box">
        <h2>站点服务管理</h2>
        <div class="box-bd">
          <!-- 头 -->
          <el-row :gutter="20">
            <el-col :span="8">
              <el-button type="primary" @click="pAdd()">添加站点服务</el-button>
              <el-button type="warning" @click="dialogLog.state = true"
                >查看日志</el-button
              >
            </el-col>
          </el-row>

          <!-- 表格 -->
          <el-table
            style="margin-top:20px"
            v-loading="tableLoading"
            class="task-table"
            :data="tableData"
          >
            <el-table-column prop="service_name" label="站点服务名称">
            </el-table-column>
            <el-table-column prop="site_name" label="站点名称">
            </el-table-column>
            <el-table-column prop="model_name" label="模型名称">
            </el-table-column>
            <el-table-column label="点位图">
              <template v-slot="scope">
                <img
                  width="100"
                  :src="scope.row.service_cover_image || ''"
                  alt=""
                />
              </template>
            </el-table-column>
            <el-table-column label="状态">
              <template slot-scope="scope">
                <el-tag type="success" v-if="scope.row.service_status === 1">
                  启用
                </el-tag>
                <el-tag type="warning" v-if="scope.row.service_status === 0">
                  禁用
                </el-tag>
              </template>
            </el-table-column>

            <el-table-column label="操作" width="180">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  type="warning"
                  icon="el-icon-edit"
                  @click="handleEdit(scope.row)"
                  >编辑</el-button
                >
                <el-button
                  size="mini"
                  type="danger"
                  icon="el-icon-delete"
                  @click="remove(scope.row)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            :pager-count="15"
            @current-change="pageChange"
            @size-change="sizeChange"
            :page-sizes="[7, 15, 20]"
            layout="total, sizes, prev, pager, next"
            :page-size="this.query.limit"
            background
            :total="exp.total"
          >
          </el-pagination>
        </div>
      </div>
    </el-card>
    <!-- 添加/删除对话框 -->
    <el-dialog
      v-dialogDrag
      :title="title"
      class="dia"
      :visible.sync="dialogVisible"
      :before-close="formClose"
    >
      <el-form :rules="formRules" ref="formRef" :model="form">
        <el-form-item
          prop="service_name"
          label="服务名称"
          :label-width="formLabelWidth"
        >
          <el-input
            @change="handleChange"
            v-model="form.service_name"
            placeholder="请输入服务名称"
          ></el-input>
        </el-form-item>
        <el-form-item
          prop="site_id"
          label="站点名称"
          :label-width="formLabelWidth"
        >
          <el-select
            v-model="form.site_id"
            style="width: 100%"
            placeholder="请选择站点名称"
            @change="handleChange"
          >
            <el-option
              v-for="item in siteListData"
              :key="item.site_id"
              :label="item.site_name"
              :value="item.site_id"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          prop="type"
          label="站点服务"
          :label-width="formLabelWidth"
        >
          <el-select
            v-model="form.type"
            style="width: 100%"
            placeholder="请选择站点服务"
            @change="handleChange"
          >
            <el-option
              v-for="item in typeListData"
              :key="item.type_id"
              :label="item.name"
              :value="item.type_id"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          prop="service_cover_image"
          label="封面图"
          :label-width="formLabelWidth"
        >
          <el-upload
            :headers="token"
            accept=".jpg,.png"
            :on-remove="imgRemove"
            :limit="1"
            :action="imgUrl"
            list-type="picture"
            :on-exceed="exceed"
            :on-success="imgSuccess"
            name="image"
            :file-list="fileList"
          >
            <el-button size="small" type="primary">上传</el-button>
          </el-upload>
        </el-form-item>
        <el-form-item
          prop="mould_id"
          label="模型"
          :label-width="formLabelWidth"
        >
          <el-select
            v-model="form.mould_id"
            style="width: 100%"
            placeholder="请选择模型"
            @change="handleModelChange"
          >
            <el-option
              v-for="item in modelListData"
              :key="item.mould_id"
              :label="item.mould_name"
              :value="item.mould_id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="addOrEdit === 2"
          prop="service_status"
          label="状态"
          :label-width="formLabelWidth"
        >
          <el-switch
            @change="handleChange"
            v-model="form.service_status"
            :active-value="1"
            :inactive-value="0"
            active-color="#13ce66"
            inactive-color="#ff4949"
          />
        </el-form-item>
        <el-row style="margin:20px 0 20px 110px;" class="ceshi">
          <div
            style="display: flex;"
            v-for="item in modelData.spec_list"
            :key="item.spec_id"
          >
            <span style="margin-right:30px"> {{ item.spec_name }}</span>
            <el-checkbox-group v-model="checkList">
              <el-tag type="success" v-if="item.spec_item_list.length === 0"
                >该规格下暂时没有属性</el-tag
              >
              <el-checkbox
                @change="handleCheckboxChange(checkList, service_spec)"
                v-else
                v-for="itm in item.spec_item_list"
                :key="itm.item_id"
                :label="itm.item_id"
                >{{ itm.item_name }}</el-checkbox
              >
            </el-checkbox-group>
          </div>
        </el-row>
        <el-table
          border
          :key="isUpdate"
          class="dynamic-table"
          ref="dynamic-table"
          :data="tableRes"
        >
          <el-table-column width="500" label="规格">
            <el-table-column
              width="100"
              v-for="item in columnRes"
              :key="item.spec_id"
              :label="item.spec_name"
              :prop="item.spec_id.toString()"
            >
            </el-table-column>
          </el-table-column>

          <el-table-column label="价格" prop="price">
            <template v-slot="scope">
              <!-- <el-input
                @input="priceChange(tableRes, scope.$index, scope.row)"
                v-model="scope.row.price"
                style="width:100px"
                size="small"
              ></el-input> -->
              <input
                type="number"
                class="original-input"
                @input="priceChange(tableRes, scope.$index, scope.row)"
                v-model="scope.row.price"
                style="width:100px"
              />
            </template>
          </el-table-column>
          <el-table-column label="图片">
            <template v-slot="scope">
              <el-upload
                :headers="token"
                accept=".jpg,.png"
                :show-file-list="false"
                :file-list="scope.row.price_image"
                :action="imgUrl"
                list-type="picture"
                :on-success="
                  (response, file, fileList) =>
                    specImgSuccess(
                      response,
                      file,
                      fileList,
                      scope.$index,
                      tableRes,
                      scope.row
                    )
                "
                name="image"
              >
                <img
                  v-if="
                    scope.row.price_image && scope.row.price_image.length !== 0
                  "
                  :src="scope.row.price_image[0].url"
                  class="avatar"
                  width="80"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </template>
          </el-table-column>
        </el-table>
      </el-form>
      <!-- 功能栏 -->
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancelAE()">取 消</el-button>
        <el-button type="primary" @click="confirmAE()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  serviceList,
  typeList,
  siteList,
  modelList,
  getModel,
  editService,
  addService,
  serviceInfo,
  delService,
} from '@/api/edu/siteservice.js'
import checkanalyseVue from '../Psi/inventorycheck/checkanalyse.vue'

export default {
  data() {
    return {
      dialogLog: {
        state: false,
      },
      checkList: [],
      isEdit: false,
      tableLoading: false,
      warningText: '',
      exp: {
        total: 0,
      },
      serviceList: [],
      query: {
        page: 1,
        page_code: '',
        limit: 7,
      },
      isUpdate: false,
      dialogVisible: false,
      changeDialogVisible: false,
      formRules: {
        service_name: {
          required: true,
          message: '请输入服务名称',
          trigger: 'blur',
        },
        site_id: {
          required: true,
          message: '请选择站点名称',
          trigger: 'blur',
        },
        mould_id: {
          required: true,
          message: '请选择模型',
          trigger: 'blur',
        },
        type: {
          required: true,
          message: '请选择站点服务',
          trigger: 'blur',
        },
      },
      siteListData: [],
      modelListData: [],
      typeListData: [],
      siteListData: [],
      title: '添加站点服务',
      addOrEdit: 1,
      formLabelWidth: '160px',
      optionsTask: [],

      form: {
        service_name: '',
        site_name: '',
        site_id: '',
        type: '',
        service_status: 1,
        service_id: '',
        mould_id: '',
        service_cover_image: '',
      },
      service_spec: [],
      tableData: [],
      provList: [],

      modelData: {
        spec_list: [],
      },
      imgVisible: false,
      tableRes: [],
      columnidRes: [],
      token: {
        'Accept-Token': window.sessionStorage.getItem('token'),
      },
      columnRes: [],
      fileList: [],
    }
  },
  created() {
    this.getList()
    this.getOptions()
  },
  beforeUpdate() {
    // table数据更新后，重新渲染table避免闪动
    this.$nextTick(() => {
      if (this.$refs['dynamic-table']) {
        this.$refs['dynamic-table'].doLayout()
      }
    })
  },
  computed: {
    imgUrl() {
      return this.$store.state.imgUrl
    },
  },
  watch: {
    columnidRes() {
      let res = []

      this.modelData.spec_list.forEach((spec) => {
        this.columnidRes.forEach((id) => {
          //   console.log(id)
          if (id === spec.spec_id) {
            // console.log('===')
            res.push(spec)
          }
        })
      })
      this.columnRes = res
    },
  },
  methods: {
    exceed() {
      this.$message({
        message: '只能上传一张图片',
        type: 'warning',
      })
    },
    priceImgUrl(row) {
      return
    },
    imgSuccess(response) {
      this.form.service_cover_image = response.data.url
    },
    specImgSuccess(response, file, fileList, index, table, row) {
      console.log(row)
      //   this.tableRes[index].price_image = []
      this.$set(this.tableRes[index], 'price_image', [])
      if (response.code === 0) {
        //this.tableRes[index].price_image.push({ url: response.data.url })
        this.$set(this.tableRes[index], 'price_image', [
          { url: response.data.url, name: '??' },
        ])
        this.isUpdate = !this.isUpdate
        //  this.$set(this.tableRes, index, row)
      } else {
        this.$message.error(response.message)
      }

      // this.$forceUpdate()
    },

    //移除合同的事件
    imgRemove(file, fileList) {
      this.form.service_cover_image = ''
    },
    async handleModelChange() {
      const { data: res } = await getModel({
        mould_id: this.form.mould_id,
      })
      this.modelData = res.data
      // console.log(this.modelData)
    },

    async getOptions() {
      const { data: res1 } = await siteList()
      const { data: res2 } = await modelList()
      const { data: res3 } = await typeList()
      this.siteListData = res1.data
      this.modelListData = res2.data
      this.typeListData = res3.data.data
    },
    pageChange(val) {
      this.query.page = val
      this.getList()
    },
    sizeChange(val) {
      this.query.limit = val
      this.getList()
    },
    async getList() {
      this.tableLoading = true
      const { data: res } = await serviceList(this.query)
      console.log(res)
      if (res.code === 1) {
        this.tableData = res.data.data
        this.exp.total = res.data.total
      } else {
        this.$message.error('获取数据失败！')
      }
      this.tableLoading = false
    },
    // 添加/编辑商品对话框关闭事件
    formClose() {
      this.$confirm('确认关闭？')
        .then((_) => {
          this.cancelAE()
          // this.getDepartmentList()
          done()
        })
        .catch((_) => {})
    },
    handleChange() {
      this.isEdit = true
    },
    handleChangeClose(done) {
      this.$confirm('确认关闭？')
        .then((_) => {
          this.changeDialogVisible = false
          this.getList()
          done()
        })
        .catch((_) => {})
    },
    // 取消
    cancelAE() {
      this.title = '添加站点服务'
      this.addOrEdit = 1
      this.isEdit = false
      this.form = {
        service_name: '',
        site_name: '',
        site_id: '',
        mould_id: '',
        type: '',
        service_status: 1,
        service_id: '',
        service_cover_image: '',
      }
      this.fileList = []
      this.checkList = []
      this.tableRes = []
      if (this.$refs.formRef) {
        setTimeout(() => {
          this.$refs.formRef.clearValidate()
        }, 30)
      }

      this.dialogVisible = false
    },

    // 新增打开
    pAdd(row) {
      console.log(row)

      this.dialogVisible = true
    },
    // 编辑打开
    async handleEdit(row) {
      console.log(row)
      this.dialogVisible = true
      this.title = '编辑站点服务'
      this.addOrEdit = 2
      const { data: res } = await serviceInfo({
        service_id: row.service_id,
      })
      // console.log(res.data)
      // 处理基础信息回显
      this.form.mould_id = res.data.mould_id
      this.form.service_id = row.service_id
      this.form.site_name = res.data.site_name
      this.form.service_name = res.data.service_name
      this.form.type = res.data.type_id
      this.form.site_id = res.data.site_id
      this.form.service_status = res.data.service_status
      this.form.service_id = res.data.service_id
      this.form.service_cover_image = res.data.service_cover_image
      this.fileList.push({ url: res.data.service_cover_image })

      // 处理规格规格表格的回显
      const { data: res1 } = await getModel({
        mould_id: this.form.mould_id,
      })
      this.modelData = res1.data

      const service_spec = res.data.service_spec
      this.service_spec = service_spec
      let checkArr = []
      if (service_spec.length !== 0) {
        service_spec.forEach((item) => {
          const keyArr = item.price_key.split('-')
          // console.log(keyArr)
          keyArr.forEach((i) => {
            checkArr.push(i)
          })
        })
      }

      checkArr = Array.from(new Set(checkArr))
      for (let i = 0; i < checkArr.length; i++) {
        checkArr[i] = Number(checkArr[i])
      }
      console.log(checkArr)
      this.checkList = checkArr
      this.handleCheckboxChange(this.checkList, service_spec)
    },
    priceChange(table, index, row) {
      //console.log(index)
      console.log(row.price)

      //this.tableRes[index].price = row.price
      this.$set(table, index, row)
      //this.$forceUpdate()
    },
    // 删除
    remove(row) {
      this.$confirm('确认删除？')
        .then(async (_) => {
          const { data: res } = await delService({
            service_id: row.service_id,
          })
          if (res.code === 1) {
            this.$message.success('删除成功！')
            this.getList()
          } else {
            this.$message.error(res.message)
          }
          this.cancelAE()
          // this.getDepartmentList()
          done()
        })
        .catch((_) => {})
    },
    confirmAE(e) {
      // console.log(e)
      this.$refs.formRef.validate(async (valid) => {
        if (valid) {
          if (this.addOrEdit === 1) {
            // 添加
            const query = {}
            query.site_id = this.form.site_id
            query.service_name = this.form.service_name
            query.service_cover_image = this.form.service_cover_image
            query.mould_id = this.form.mould_id
            query.type = this.form.type
            query.spec_price_arr = []
            if (this.tableRes.length !== 0) {
              this.tableRes.forEach((item) => {
                let obj = {}
                let price_key = []
                let price_key_name = []
                var reg = /^[0-9]{1,20}$/

                for (let key in item) {
                  console.log(key)
                  if (key === 'price_image') {
                    obj.price_image = item[key][0].url
                  } else if (key === 'price') {
                    obj.price = item[key]
                  } else if (!reg.test(key)) {
                    price_key.push(item[key])
                    price_key_name.push(key)
                  }
                }
                let str1 = price_key.join('-')
                let str2 = price_key_name.join('-')
                console.log(str1)
                console.log(str2)
                obj.price_key_name = str2
                obj.price_key = str1

                console.log(obj)
                query.spec_price_arr.push(obj)
              })
            }
            query.spec_price_arr = JSON.stringify(query.spec_price_arr)
            console.log(query)
            const { data: res } = await addService(query)
            if (res.code === 1) {
              this.$message.success('添加成功')
              this.getList()
              this.cancelAE()
            } else {
              this.$message.error(res.message)
            }
          } else {
            // 编辑
            const query = {}
            query.site_id = this.form.site_id
            query.service_name = this.form.service_name
            query.service_cover_image = this.form.service_cover_image
            query.mould_id = this.form.mould_id
            query.type = this.form.type
            query.service_id = this.form.service_id
            query.spec_price_arr = []
            if (this.tableRes.length !== 0) {
              this.tableRes.forEach((item) => {
                let obj = {}
                let price_key = []
                let price_key_name = []
                for (let key in item) {
                  console.log(key)

                  if (key === 'price_image') {
                    obj.price_image = item[key][0].url
                  } else if (key === 'price') {
                    obj.price = parseInt(item[key]).toFixed(2)
                    obj.price = Number(obj.price)
                  } else if (key === 'price_id') {
                    obj.price_id = item[key]
                  } else if (isNaN(parseInt(key))) {
                    if (key !== 'price_id' && key !== 'price_key') {
                      price_key.push(item[key])
                      price_key_name.push(key)
                    }
                  }
                }
                let str1 = price_key.join('-')
                let str2 = price_key_name.join('-')
                //    console.log(str1)
                //   console.log(str2)
                obj.price_key_name = str2
                obj.price_key = str1

                //   console.log(obj)
                query.spec_price_arr.push(obj)
              })
            }
            query.spec_price_arr = JSON.stringify(query.spec_price_arr)
            console.log(query)
            const { data: res } = await editService(query)
            if (res.code === 1) {
              this.$message.success('编辑成功')
              this.cancelAE()
              this.getList()
            } else {
              this.$message.error(res.message)
            }
          }
        }
      })
    },
    multi1(arr1, arr2) {
      if (arr1.length == 0) {
        // arr1 是空数组 []
        return arr2
      }
      if (arr2.length == 0) {
        return arr1
      }
      let res = []
      for (let i = 0; i < arr1.length; i++) {
        for (let j = 0; j < arr2.length; j++) {
          res.push(String(arr1[i]) + ',' + String(arr2[j]))
        }
      }
      return res
    },
    allGroups1(arr) {
      return arr.reduce((total, cur) => {
        let ress = this.multi1(total, cur)
        // console.log(ress);
        return ress
      }, [])
    },
    handleCheckboxChange(val, spec) {
      this.tableRes = []
      //    console.log(this.checkList)
      let res = []
      // spec_id和规格值组成的二维数组[['17-冰'],['18-抹茶'],['19-珍珠']]
      let twoDimensionSpecArr = []

      // 处理表格动态列数据
      this.modelData.spec_list.forEach((spec) => {
        if (this.checkList.length === 0) {
          this.columnRes = []
          return
        }
        let oneDimensionSpecArr = []
        let oneDimensionItemArr = []

        this.checkList.forEach((id) => {
          spec.spec_item_list.forEach((item) => {
            if (item.item_id === id) {
              // 处理列id数组
              res.push(spec.spec_id)
              res = [...new Set(res)]
              // console.log(res)
              this.columnidRes = res

              // 处理二维数组

              let tempStr =
                item.spec_id + '-' + item.item_name + '-' + item.item_id
              // console.log(tempStr2)
              let tempStr1 = item.item_name
              // let tempStr2 = item.item_id + '-' + item.item_name

              oneDimensionSpecArr.push(tempStr)
              // 不带id
              oneDimensionItemArr.push(tempStr1)
              // oneDimensionItemIdArr.push(tempStr2)
              // console.log(oneDimensionItemIdArr)
            }
          })
        })
        // 生成规格二维数组
        twoDimensionSpecArr.push(oneDimensionSpecArr)
        // twoDimensionItemIdArr.push(oneDimensionItemIdArr)
      })
      // 排列组合好后的一维数组 spec_id组
      let res1 = this.allGroups1(twoDimensionSpecArr)
      //  console.log(res1)
      if (this.addOrEdit === 1) {
        // 添加
        res1.forEach((item) => {
          //   console.log(item)
          let itemArr = item.split(',')
          let arrObj = {}
          itemArr.forEach((i) => {
            //   console.log(i)
            let f = i.split('-') //['18','冰']
            f.forEach((i) => {
              this.$set(arrObj, f[0], f[1])
              this.$set(arrObj, f[1], f[2])
              this.$set(arrObj, 'price_image', [])
              this.$set(arrObj, 'price', '')
            })
          })
          this.tableRes.push(arrObj)
        })
      } else {
        // 编辑
        res1.forEach((item) => {
          //   console.log(item)
          let itemArr = item.split(',')
          let arrObj = {}
          itemArr.forEach((i) => {
            //  console.log(i)
            let f = i.split('-') //['18','冰']
            f.forEach((i) => {
              this.$set(arrObj, f[0], f[1])
              this.$set(arrObj, f[1], f[2])
            })
          })
          this.tableRes.push(arrObj)

          // 处理表格中的数据回显
          this.tableRes.forEach((item) => {
            let itemidArr = []
            for (let key in item) {
              if (
                isNaN(parseInt(key)) &&
                key !== 'price' &&
                key !== 'price_id' &&
                key !== 'price_image' &&
                key !== 'price_key'
              ) {
                itemidArr.push(item[key])
              }
            }

            item.price_key = itemidArr.join('-')
            console.log(spec)
            if (spec.length !== 0) {
              spec.forEach((spec) => {
                if (spec.price_key == item.price_key) {
                  console.log('...')
                  item.price_id = spec.price_id

                  item.price_image = []
                  console.log(spec.price_image)
                  item.price_image.push({ url: spec.price_image })
                  item.price = parseInt(spec.price).toFixed(2)
                }
              })
            }
          })
        })
      }
    },
  },
}
</script>

<style scoped>
.content {
  display: flex;
  align-items: center;
}

.content .warning-icon {
  width: 44px;
  height: 32px;
  margin-right: 10px;
  background: url('../../../assets/exclamationmark.png') no-repeat;
}
.dynamic-table {
  font-size: 14px !important;
  font-weight: 400 !important;
}
.dynamic-table >>> .el-table__cell {
  padding: 5px 0;
}
.original-input {
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  height: 20px;
}
.dia .el-checkbox >>> {
  zoom: 120% !important;
}
</style>
